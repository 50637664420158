import React from 'react';

// Layouts

// Views

const App = () => {
  window.location.href = 'https://www.timbudweg.de';
};

export default App;
